import { FC } from "react";
import { Page } from "components/templates/Page";
import { DesktopSpacer, Spacer } from "components/templates/Spacer";
import { teamTailorService } from "@services";
import { Job } from "services/teamTailor/types";
import { VideoPlayerSection } from "components/molecules/VideoPlayerSection";
import { color } from "@styles";
import { HeroSection } from "components/organisms/HeroSection";
import { Clouds } from "./Clouds";
import { NewZigZag } from "components/molecules/NewZigZag";
import { QuoteCarousel } from "components/organisms/QuoteCarousel";
import { ProductIntro } from "components/molecules/ProductIntro";
import { StandardSection } from "components/templates/StandardSection";
import { ProductCard } from "components/molecules/ProductCard";
import { JobCard } from "components/molecules/JobCard";
import { CareersCTA } from "components/molecules/CareersCTA";
import { ResponsiveNextImage } from "components/atoms/ResponsiveNextImage";
import {
  BenefitCardWrap,
  GlassDoorSection,
  JobCardWrap,
  OpenRolesSection,
  Padding,
} from "./styles";
import { quoteSlides, yucrewBenefitCards } from "./data";
import { Anchor } from "components/atoms/Anchor";
import Link from "next/link";
import { VideoPlayer } from "components/atoms/VideoPlayer";
import { smoothScrollTo } from "@utils";
import { SPECULATIVE_JOB_ID } from "utils/_constants";
import { LatestCaseStudy } from "utils/server";
import { ResponsiveZigZagImage } from "components/atoms/ResponsiveZigZagImage";

interface Props {
  jobs: Job[];
  latestCaseStudy: LatestCaseStudy;
}

const Careers: FC<Props> = ({ jobs, latestCaseStudy }) => {
  const hrMailTo = `mailto:hello@yulife.com?subject=${encodeURIComponent("Working at YuLife")}`;
  const glassdoorLink =
    "https://www.glassdoor.co.uk/Overview/Working-at-YuLife-EI_IE2324470.11,17.htm";
  const displayableJobs = (jobs || []).filter((job) => job.id !== SPECULATIVE_JOB_ID);
  const NO_JOBS = displayableJobs.length < 1;
  const speculativeJobLink = (jobs || []).filter((job) => job.id === SPECULATIVE_JOB_ID)[0]?.links;

  const callToActionUrl = speculativeJobLink
    ? `/careers/${teamTailorService.getSlugFromJobUrl(speculativeJobLink)}`
    : hrMailTo;

  return (
    <Page
      navColor="transparent"
      latestCaseStudy={latestCaseStudy}
      hero={
        <HeroSection
          eyeBrow="Careers"
          title="Come join the YuCrew"
          subHeading="YuLife is on a mission to inspire people to live their best lives every day, and we’re looking for the people to help us do that."
          backgroundColor="rgba(255, 251, 229, 1)"
          image="/static/pages/careers/yulife-yugi-giraffe-with-friends.svg"
          alt="An illustration of the YuLife giraffe mascot, with a other animals from the YuLife app."
          buttonText="See open roles"
          buttonLinkFunction={() => smoothScrollTo(window, "open-roles-section", 1100)}
          textPt="4rem"
          clouds={<Clouds />}
          statBar={{
            backgroundColor: color.S3100,
            stats: [
              {
                value: "290",
                description: "YuCrew",
              },
              {
                value: "19",
                description: "countries",
                marginRight: ".3rem",
                marginLeft: "1rem",
              },
              {
                value: "4.7",
                description: "Glassdoor rating",
              },
            ],
          }}
        />
      }
      fullScreenHero
    >
      <Spacer height={["0", "7.5rem", "2rem"]} />
      <NewZigZag
        title="Work with the best insurance & tech talent"
        description="We attract diverse talents from all over the world. As a remote-first workplace, we work with best-in-class talent, no matter their timezone."
        align="left"
        paragraphpb="0rem"
      >
        <VideoPlayer webm="/static/img/VideoPlayer/YuCrew_Carousel.mp4" />
      </NewZigZag>
      <NewZigZag
        title="Work in a place that is purpose-driven"
        description={
          <p>
            We are a certified{" "}
            <Anchor color={color.P100} href="/blog/yulife-b-borp-certification/">
              B-Corp 🚀
            </Anchor>{" "}
            whose mission is to build a global financial services business whose products inspire
            people to live healthier, happier lives.
          </p>
        }
        align="left"
        reverse
        paragraphpb="0rem"
        pb="7.5rem"
      >
        <ResponsiveZigZagImage
          src="/static/pages/careers/b-corp-logo.png"
          alt="B-Corp Logo"
          width={544}
          height={544}
        />
      </NewZigZag>
      <NewZigZag
        title="Bring your whole self to work"
        description="Our company values are “Love being Yu”- we live our values, and have created something pretty unique – a workplace where you are celebrated for everything that you are. We don’t just champion wellness for our customers, we champion that for our employees, too."
        align="left"
        paragraphpb="0rem"
        pt="0rem"
      >
        <ResponsiveZigZagImage
          src="/static/pages/careers/yulife-love-being-yu-banner.svg"
          width={544}
          height={544}
          alt="YuLife banner"
        />
      </NewZigZag>
      <VideoPlayerSection
        title="Welcome to the Yuniverse"
        subtitle="We are a remote-first organisation with employees from all across the globe. But we still have our London headquarters - that is lovingly dubbed “the Yuniverse” - check it out in this video!"
        altText="Outside the YuLife office"
        vimeoId="791939586"
        videoImg="/static/img/VideoPlayer/YuLifeOffice.jpg"
        backgroundColor={color.B220}
      />
      <QuoteCarousel slides={quoteSlides} />
      <DesktopSpacer height="5rem" />
      <ProductIntro
        title="YuCrew benefits"
        body="YuLife provides competitive and inclusive benefits to promote overall employee wellbeing. We believe in incentivising you to be your best Yu and do your best work."
      />
      <StandardSection pt="3.75rem">
        <BenefitCardWrap>
          {yucrewBenefitCards.map((card) => {
            return (
              <ProductCard
                key={card.title}
                iconName={card.iconName}
                title={card.title}
                description={card.description}
                width="17rem"
                height="24.5rem"
                hasPadding={false}
                justifyContent={card.justifyContent ? card.justifyContent : "flex-start"}
              />
            );
          })}
        </BenefitCardWrap>
      </StandardSection>
      <GlassDoorSection>
        <Link href={glassdoorLink}>
          <img
            src="/static/companies/glassdoor.png"
            alt="Glassdoor logo"
            style={{ transform: "scale(0.8)" }}
          />
        </Link>
      </GlassDoorSection>
      <OpenRolesSection id="open-roles-section">
        {displayableJobs?.length ? (
          <>
            <DesktopSpacer height="4rem" />
            <ProductIntro
              title="Open roles"
              body="Shaking up an industry is no easy feat! We need the best talent, globally. Our team come from a diverse range of backgrounds, and from all around the world. "
            />
            <Padding>
              <JobCardWrap>
                {displayableJobs?.map(
                  ({ id, attributes: { title, pitch, picture }, links, department }) => {
                    return (
                      <JobCard
                        key={id}
                        title={title}
                        description={pitch}
                        department={department}
                        image={picture?.standard}
                        link={`/careers/${teamTailorService.getSlugFromJobUrl(links)}`}
                        $isHorizontal={displayableJobs.length === 1}
                      />
                    );
                  },
                )}
              </JobCardWrap>
            </Padding>
          </>
        ) : null}
        {NO_JOBS && <DesktopSpacer height="3rem" />}
        <CareersCTA
          subtitle={`Don’t see any vacancies? If there isn’t a role here for you, but you genuinely care about the same things we do, we still want to hear from you! ${
            !speculativeJobLink
              ? "Please send a copy of your CV and an introduction letter to hello@yulife.com and let’s talk."
              : ""
          }`}
          buttonLink={callToActionUrl}
        />
      </OpenRolesSection>
      <ResponsiveNextImage
        src="/static/pages/careers/yulife-group-photo.jpg"
        quality={100}
        alt="A group shot of YuLife employees"
      />
    </Page>
  );
};

export default Careers;
